<script setup>
import { computed, useSlots } from 'vue';
import DefaultInner from '@/Components/Button/Partials/DefaultInner.vue';
import ReactiveSlot from '@/Components/Button/Partials/ReactiveSlot.vue';

const props = defineProps({
    type: {
        type: String,
        default: 'submit',
    },
    icon: {
        type: String,
        required: false,
    },
    href: {
        type: String,
        required: false,
    },
    label: String,
    processing: Boolean,
    disabled: Boolean,
    recentlySuccessful: Boolean,
    text: String,

    asA: Boolean,

    orange: Boolean,
    danger: Boolean,
    green: Boolean,
    blue: Boolean,

    fullWidth: Boolean,
    white: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const component = computed(() => {
    return props.asA ? 'a' : 'button';
});

const slots = useSlots();
</script>

<template>
    <component
        :is="component"
        :type="type"
        :href="href"
        :disabled="disabled || processing || recentlySuccessful"
        :class="{
            'bg-pink text-white ring-offset-white hover:bg-pink/80 focus:ring-pink ':
                !orange && !danger && !green && !white && !blue,
            'border-orange bg-orange text-white hover:bg-orange/80 hover:text-white ': orange,
            'hover:red/80 border-orange bg-red text-white hover:text-white': danger,
            'border-blue bg-white  text-blue hover:bg-white': white,
            'border-green bg-green text-white hover:bg-green/80 hover:text-white ': green,
            'border-blue bg-blue text-white hover:bg-blue/80 hover:text-white ': blue,
            'w-full': fullWidth,
            'w-fit': !fullWidth,
        }"
        class="relative inline-flex min-h-10 items-center self-center rounded border border-transparent px-8 py-2 text-base font-semibold uppercase leading-none tracking-widest transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none disabled:cursor-not-allowed disabled:border-0 disabled:bg-gray-200 disabled:text-gray-600"
    >
        <ReactiveSlot
            :processing="processing"
            :recently-successful="recentlySuccessful"
        >
            <DefaultInner
                v-if="!slots.default"
                :icon="icon"
                :text="text"
            />
            <div
                v-if="!slots.default"
                class="leading-tight"
            >
                {{ label }}
            </div>

            <slot />
        </ReactiveSlot>
    </component>
</template>
